import { useMemo, useState, useEffect, useCallback } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { FaroAlert } from "@components/common/faro-alert";
import { FaroTextButton } from "@components/common/faro-text-button";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { isStagingAreaUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { BaseProjectProps } from "@custom-types/project-types";
import { DEFAULT_CHUNK_SIZE, MAX_CONCURRENT_UPLOADS, ONE_MEBIBYTE, MAX_CONCURRENT_CHUNKS } from "@custom-types/upload-manager-types";
import { RevisionStatus } from "@faro-lotv/service-wires";
import { fetchCaptureTreeData } from "@hooks/data-management/use-data-management";
import { isTaskInProgress } from "@hooks/upload-tasks/upload-tasks-utils";
import { Box, ButtonGroup, Stack, SvgIcon } from "@mui/material";
import { DataManagementDropzone } from "@pages/project-details/project-data-management/data-management-dropzone";
import { DataManagementStepper } from "@pages/project-details/project-data-management/data-management-stepper/data-mangement-stepper";
import { DataManagementTable } from "@pages/project-details/project-data-management/data-management-table";
import { DataManagementTitle } from "@pages/project-details/project-data-management/data-management-title";
import { StepState, WorkflowState, StepperIndices, TableItem } from "@pages/project-details/project-data-management/data-management-types";
import {
  getUploadTasksToKeepOrRemove,
  getProcessProgress,
  getRegisterProgress,
  getTableItemsFromEntities,
  getTableItemsFromTasks,
  getUploadedIdsMap,
  getUploadProgress,
} from "@pages/project-details/project-data-management/data-management-utils";
import { cleanupUploadTasks } from "@pages/project-details/project-data-management/import-data/cancel-import-utils";
import { ImportData } from "@pages/project-details/project-data-management/import-data/import-data";
import { OpenViewerButton } from "@pages/project-details/project-data-management/open-viewer-button";
import { publishedDataSelector } from "@pages/project-details/project-data-management/published-data/published-data-selectors";
import { PublishButton } from "@pages/project-details/project-data-management/publish-button";
import {
  hasRegisterErrorSelector,
  isRegisteredSelector,
  isRegisteringSelector,
  registeredDataSelector,
} from "@pages/project-details/project-data-management/registered-data/registered-data-selectors";
import { RemoveScansDialog } from "@pages/project-details/project-data-management/remove-scans-dialog";
import {
  isProcessedSelector,
  isProcessingSelector,
  uploadedDataSelector,
  hasProcessErrorSelector,
  processScanTasksSelector,
} from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-selectors";
import { APITypes } from "@stellar/api-logic";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import {
  fetchingStatusAllCaptureTreeRevisionsSelector,
  fetchingStatusCaptureTreeSelector,
  hasFetchedAllCaptureTreeRevisionsSelector,
  hasFetchedCaptureTreeSelector,
  hasFetchCaptureTreeDataErrorSelector,
  openDraftRevisionSelector,
  captureTreeForOpenDraftRevisionSelector,
  fetchingStatusCaptureTreeForOpenDraftRevisionSelector,
  hasAnyDraftChangesSelector,
  hasFetchedCaptureTreeForOpenDraftRevisionSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { currentUserSelector } from "@store/user/user-selector";
import { selectedIdsSelector } from "@store/table/table-selector";
import { setInitialFetchingItems } from "@store/table/table-slice";
import { colorConst, sphereColors } from "@styles/common-colors";
import { UploadEndpoint } from "@utils/core-file-uploader";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import ErrorSvg from "@assets/icons/new/exclamation-mark-circle-fill_24px.svg?react";
import ArrowUpSvg from "@assets/icons/new/arrow-up_10px.svg?react";
import ArrowDownSvg from "@assets/icons/new/arrow-down_10px.svg?react";

/** Upload warning when using Shared Worker, which allows full-speed upload from background tabs. */
const WARNING_SHARED_WORKER = "Please keep your browser open. " +
"Minimizing the browser is fine, but closing this tab or the browser will stop the upload.";

/** Upload warning when not using Shared Worker, where background tabs will be throttled. */
const WARNING_REGULAR_UPLOAD = "Please keep your browser open and stay on this page. " +
  "Leaving or closing the browser may interrupt the upload.";

/**
 * The main page for the Staging Area workflow, including the progress stepper and the scan table.
 * The code is unfortunately complex and "ugly" since the basic data elements weren't constructed to work
 * well with such a workflow and the user can also add additional data later, resetting the workflow while
 * older data still exists.
 */
export function DataManagementWorkflow({project}: BaseProjectProps): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);
  const { uploadManager } = useFileUploadContext();
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isRemoveScansDialogOpen, setIsRemoveScansDialogOpen] = useState(false);
  const [scansToRemove, setScansToRemove] = useState<TableItem[]>([]);
  const selectedIds = useAppSelector(selectedIdsSelector("scans"));
  const [selectedIdsOnErrorTable, setScansToRemoveErrorTable] = useState<EntityId[]>([]);
  const [selectedIdsOnOkTable, setScansToRemoveOkTable] = useState<EntityId[]>([]);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({ projectId: project.id });
  const progressApiClient = useProgressApiClient({ projectId: project.id });

  // Previously, we also used "useBackgroundTasksTracker" to poll for background tasks,
  // but that's already included in fetchCaptureTreeData().
  const sdbBackgroundsTasks = useAppSelector(sdbBackgroundTasksSelector);

  // ##### Fetch status ##### //

  const hasFetchedCaptureTree = useAppSelector(hasFetchedCaptureTreeSelector);
  const hasFetchedCaptureTreeOpenDraft = useAppSelector(hasFetchedCaptureTreeForOpenDraftRevisionSelector);
  // fetchingStatusPrepared and hasFetchedAllCaptureTreeRevisions are also used for publishedData.
  const fetchingStatusRegistered = useAppSelector(fetchingStatusAllCaptureTreeRevisionsSelector);
  const hasFetchedAllCaptureTreeRevisions = useAppSelector(hasFetchedAllCaptureTreeRevisionsSelector);
  const fetchingStatusUploaded = useAppSelector(fetchingStatusCaptureTreeSelector);
  const fetchingStatusOpenDraft = useAppSelector(fetchingStatusCaptureTreeForOpenDraftRevisionSelector);
  const hasFetchCaptureTreeDataError = useAppSelector(hasFetchCaptureTreeDataErrorSelector);

  const isFetchingForTheFirstTime = (!hasFetchedCaptureTree || !hasFetchedCaptureTreeOpenDraft) &&
    !hasFetchedAllCaptureTreeRevisions &&
    (fetchingStatusUploaded === FetchingStatus.pending ||
     fetchingStatusRegistered === FetchingStatus.pending);

  const hasFailedToFetchUploadedData = fetchingStatusUploaded === FetchingStatus.rejected ||
    fetchingStatusOpenDraft === FetchingStatus.rejected;
  const hasFailedToFetchRegisteredData = fetchingStatusRegistered === FetchingStatus.rejected;

  // ##### Upload tasks ##### //

  const uploadTasksAll = useAppSelector(uploadTasksSelector);
  // uploadTasksSelector also returns tasks for other projects and needs to be filtered.
  // That means we also can't use hasInProgressUploadTasksSelector since it also uses tasks of other projects.
  // We don't want to show tasks from e.g. annotation attachment uploads either. -> Filter by context type.
  const uploadTasks = uploadTasksAll.filter((task) =>
    task.context?.projectId === project.id && isStagingAreaUploadTaskContext(task.context)
  );

  // ##### Uploaded data ##### //

  const uploadedEntities = useAppSelector(uploadedDataSelector);
  const isProcessing = useAppSelector(isProcessingSelector);
  const isProcessed = useAppSelector(isProcessedSelector);
  const hasProcessError: boolean = useAppSelector(hasProcessErrorSelector);

  // We cannot use the "createdAt" attribute of the scan entities to determine the upload time,
  // since it's used for the recording time of the scan.
  // Until there is a dedicated attribute for the upload time (SMETA-1540), we check the processing tasks instead.
  // Using this approach, there's the problem that, when uploading some additional scans, for the short period between
  // the end of the Upload state and the beginning of the Processing state, the old further advanced state gets shown
  // again. An alternative approach using projectApiClient.getCaptureTreeForRegistrationRevision resulted in the same
  // problem.
  const processScanTasks = useAppSelector(processScanTasksSelector);
  const scanTaskNewest = processScanTasks.length ? processScanTasks[0] : undefined;
  const timeUpload = useMemo(() => {
    return scanTaskNewest ? (new Date(scanTaskNewest.createdAt)).getTime() : 0;
  }, [scanTaskNewest]);

  // ##### Registered data ##### //

  // Revisions created by the RegistrationBackend, from newest to oldest.
  const registrationRevisions = useAppSelector(registeredDataSelector);
  const registrationRevision = registrationRevisions.length ? registrationRevisions[0] : undefined;
  const isRegistering = useAppSelector(isRegisteringSelector);
  const { hasRegisterError, hasBadRegistration } = useAppSelector(hasRegisterErrorSelector);
  const registrationId = registrationRevision ? registrationRevision.id : undefined;
  const timeRegister = useMemo(() => {
    return registrationRevision ? (new Date(registrationRevision.modifiedAt)).getTime() : 0;
  }, [registrationRevision]);
  const isRegistered = useAppSelector(isRegisteredSelector) && 0 < timeRegister && timeUpload <= timeRegister;

  // ##### Draft data ##### //

  const openDraftRevision = useAppSelector(openDraftRevisionSelector);
  const hasAnyDraftChanges = useAppSelector(hasAnyDraftChangesSelector);

  // ##### Published data ##### //

  const publishedRevisions = useAppSelector(publishedDataSelector);
  const publishedRevision = publishedRevisions.length ? publishedRevisions[0] : undefined;
  const isPublishing: boolean = false;
  const timePublish = useMemo(() => {
    return publishedRevision ? new Date(publishedRevision.modifiedAt).getTime() : 0;
  }, [publishedRevision]);
  const isPublished = !!publishedRevision && !hasAnyDraftChanges &&
    0 < timePublish && timeUpload <= timePublish && timeRegister <= timePublish;
  const hasPublishError: boolean = false;

  // ##### Table items (Part 1) ##### //

  // We don't want to show table entries for (finished) upload tasks for which there's already a member in the
  // uploaded data array.
  const uploadedIdsMap: { [key: APITypes.UUID]: boolean } = useMemo(() => {
    return getUploadedIdsMap(uploadedEntities);
  }, [uploadedEntities]);

  const { uploadTasksToKeep, uploadTasksToRemove } = useMemo(() => {
    return getUploadTasksToKeepOrRemove(uploadTasks, uploadedIdsMap);
  }, [uploadTasks, uploadedIdsMap]);

  const { tableItemsFromTasks, isUploadingFromTasks, hasUploadErrorFromTasks } = useMemo(() => {
    return getTableItemsFromTasks(uploadTasksToKeep, currentUser);
  }, [uploadTasksToKeep, currentUser]);

  // ##### Upload ##### //

  // Use the same check as in hasInProgressUploadTasksSelector here:
  const isUploading = isUploadingFromTasks || uploadTasksToKeep.some((task) => isTaskInProgress(task));
  const isUploaded = !isUploading && 0 < uploadedEntities.length;

  // state to manage the close button on uploading alert box
  const [isUploadingAlertOpen, setIsUploadingAlertOpen] = useState<boolean>(true);

  // each time a new scan is uploaded the isUploadingAlertOpen flag should be marked to true
  useEffect(() => {
    if (isUploading) {
      setIsUploadingAlertOpen(true);
    }
  }, [isUploading]);

  // ##### Dev features ##### //

  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const { hasUserPermissionProjectLevel } = useHasUserValidPermissionProjectLevel();
  const captureTreeOpenDraft = useAppSelector(captureTreeForOpenDraftRevisionSelector);
  // Allow to set the maximum number of concurrent file uploads.
  const [maxConcurrentUploads, setMaxConcurrentUploads] = useState<number>(0);
  // Allow to set the maximum number of concurrent chunk uploads per file.
  const [maxConcurrentChunks, setMaxConcurrentChunks] = useState<number>(0);
  // Allow to set the chunk size in MiB.
  const [chunkSizeMib, setChunkSizeMib] = useState<number>(DEFAULT_CHUNK_SIZE / ONE_MEBIBYTE);
  // Allow to set the upload endpoint, overriding the auto-detection.
  const [uploadEndpoint, setUploadEndpoint] = useState<UploadEndpoint>("auto");

  const hasRegularPermission: boolean = useMemo(() => {
      return (
        project.archivingState === APITypes.ArchivingState.UNARCHIVED &&
        hasUserPermissionProjectLevel({
          permissionName: RequiredPermissionProjectLevelName.canViewDataManagement,
          project,
        })
      );
    },
    [hasUserPermissionProjectLevel, project]
  );

  const captureTreeInfo = useCallback(async () => {
    /* eslint-disable no-console -- Dev feature */
    console.log("Fetching capture tree data...");
    const latest = await fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient);
    console.log("Revisions", latest.revisions);
    console.log("Main or Draft entities", latest.mainEntities);
    console.log("Open Draft revision", latest.openDraftRevision);
    console.log("Open Draft entities", latest.openDraftEntities);
    console.log("Open Draft entities (changed)",
      latest.openDraftEntities.filter((entity) => entity.status !== RevisionStatus.initialized)
    );
    /* eslint-enable no-console */
  }, [dispatch, progressApiClient, projectApiClient]);

  const captureTreeDiff = useMemo(() => {
    if (!isDevModeEnabled) {
      return "";
    }
    const addedEntities = captureTreeOpenDraft.filter((entity) =>
      entity.status === RevisionStatus.added
    );
    const deletedEntities = captureTreeOpenDraft.filter((entity) =>
      entity.status === RevisionStatus.deleted || entity.status === RevisionStatus.deletedExplicitly
    );
    const modifiedEntities = captureTreeOpenDraft.filter((entity) =>
      entity.status === RevisionStatus.modified
    );
    return `(+) ${addedEntities.length}, (-): ${deletedEntities.length}, (M): ${modifiedEntities.length}`;
  }, [captureTreeOpenDraft, isDevModeEnabled]);

  function promptMaxConcurrentUploads(): void {
    // For a dev feature, "prompt" is good enough.
    const str = window.prompt(
      "Enter the maximum number of concurrent file uploads and chunk uploads per file (integer greater than 0):\n\n" +
        "files, chunks",
      `${maxConcurrentUploads || MAX_CONCURRENT_UPLOADS}, ${maxConcurrentChunks || MAX_CONCURRENT_CHUNKS}`
    );
    if (str) {
      const [files, chunks] = str.trim().split(/[^0-9.]+/).map((s) => parseFloat(s));
      uploadManager.setMaxConcurrentUploads(files, chunks, /* manual */ true);
      // If the function didn't throw, it worked.
      setMaxConcurrentUploads(files);
      setMaxConcurrentChunks(chunks);
    }
  }

  function promptChunkSizeMib(): void {
    // For a dev feature, "prompt" is good enough.
    const str = window.prompt(
      "Enter the chunk size in MiB (Default 5):",
      chunkSizeMib.toString()
    );
    if (str) {
      const value = Number(str);
      const chunkSize = value * ONE_MEBIBYTE;
      uploadManager.setChunkSize(chunkSize);
      // If the function didn't throw, it worked.
      setChunkSizeMib(value);
    }
  }

  function promptUploadEndpoint(): void {
    let str = window.prompt(
      "Enter the desired upload endpoint:\n\n" +
        "a = auto | f = frontdoor | s = storage",
      uploadEndpoint
    );
    if (str) {
      if (str.startsWith("a")) {
        str = "auto";
      } else if (str.startsWith("f")) {
        str = "frontdoor";
      } else if (str.startsWith("s")) {
        str = "storage";
      }
      uploadManager.setUploadEndpoint(str as UploadEndpoint);
      // If the function didn't throw, it worked.
      setUploadEndpoint(str as UploadEndpoint);
    }
  }

  // ##### Determine the state ##### //

  // Determine the overall state.
  // The order should support uploading additional scans after later states have already been reached.
  // So the progress must be set to e.g. uploading although there already is e.g. published data.
  let state: WorkflowState = "start";
  let iActiveStep = StepperIndices.upload;

  if (isUploading) {
    state = "uploading";
    iActiveStep = StepperIndices.upload;
  } else if (hasUploadErrorFromTasks) {
    state = "uploadError";
    iActiveStep = StepperIndices.upload;
  } else if (isPublishing) {
    state = "publishing";
    iActiveStep = StepperIndices.publish;
  } else if (hasPublishError) {
    state = "publishError";
    iActiveStep = StepperIndices.publish;
  } else if (isPublished) {
    state = "published";
    iActiveStep = StepperIndices.publish;
  } else if (isProcessing) {
    state = "processing";
    iActiveStep = StepperIndices.process;
  } else if (isRegistering) {
    state = "registering";
    iActiveStep = StepperIndices.register;
  } else if (hasRegisterError) {
    state = "registerError";
    iActiveStep = StepperIndices.register;
  } else if (hasBadRegistration) {
    state = "registerBadResult";
    iActiveStep = StepperIndices.register;
  } else if (isRegistered) {
    state = "registered";
    iActiveStep = StepperIndices.publish;
  } else if (hasProcessError) {
    state = "processError";
    iActiveStep = StepperIndices.process;
  } else if (isProcessed) {
    state = "processed";
    iActiveStep = StepperIndices.register;
  } else if (isUploaded) {
    state = "uploaded";
    iActiveStep = StepperIndices.process;
  }

  // Determine the state of the stepper icons.
  let uploadStepState: StepState = "todo";
  if (StepperIndices.process <= iActiveStep) {
    uploadStepState = "done";
  } else if (state === "uploading") {
    uploadStepState = "active";
  } else if (state === "uploadError") {
    uploadStepState = "error";
  }

  let processStepState: StepState = "todo";
  if (StepperIndices.register <= iActiveStep) {
    processStepState = "done";
  } else if (state === "processing") {
    processStepState = "active";
  } else if (state === "processError") {
    processStepState = "error";
  }

  let registerStepState: StepState = "todo";
  if (StepperIndices.publish <= iActiveStep) {
    registerStepState = "done";
  } else if (state === "registering") {
    registerStepState = "active";
  } else if (state === "registerError" || state === "registerBadResult") {
    registerStepState = "error";
  }

  let publishStepState: StepState = "todo";
  if (state === "published") {
    publishStepState = "done";
  } else if (state === "publishing" || (state === "registered" && hasAnyDraftChanges)) {
    publishStepState = "active";
  } else if (state === "publishError") {
    publishStepState = "error";
  }

  const isInProgress = state === "uploading" || state === "processing" || state === "registering" || state === "publishing";
  // Hide or disable the upload buttons while the data is processed in some way.
  const uploadBtnDisabledTooltip = isInProgress ?
    "Uploading additional data is disabled during data processing." :
    undefined;

  const isPublishDisabled = publishStepState !== "active" && !isDevModeEnabled;
  const isPublishDevMode = publishStepState !== "active" && isDevModeEnabled;

  // ##### Table items (Part 2) ##### //

  // We need to know if state is "published" before being able to determine the table items based on uploadedEntities.
  const tableItemsFromUploads = useMemo(() => {
    return getTableItemsFromEntities(state, uploadedEntities);
  }, [state, uploadedEntities]);

  const tableItems = useMemo(() => {
    return [ ...tableItemsFromTasks, ...tableItemsFromUploads ];
  }, [tableItemsFromTasks, tableItemsFromUploads]);

  // ##### Progress values ##### //

  const uploadProgress = useMemo(() => {
    const tasks = (state === "uploading" || state === "uploadError") ? uploadTasks : uploadTasksToKeep;
    return getUploadProgress(state, tasks);
  }, [state, uploadTasks, uploadTasksToKeep]);

  const processProgress = useMemo(() => {
    return getProcessProgress(state, captureTreeOpenDraft, sdbBackgroundsTasks);
  }, [state, captureTreeOpenDraft, sdbBackgroundsTasks]);

  const registerProgress = useMemo(() => {
    return getRegisterProgress(state, registrationRevision);
  }, [state, registrationRevision]);

  // ##### Dropzone page and error page ##### //

  const isEmpty = tableItems.length === 0 && !isFetchingForTheFirstTime;
  const shouldShowError = isEmpty &&
    (hasFailedToFetchUploadedData || hasFailedToFetchRegisteredData || hasFetchCaptureTreeDataError);
  const shouldShowFullDropzone = isEmpty && !shouldShowError && !!project;
  const shouldShowHiddenDropzone = !shouldShowFullDropzone && !isInProgress && !!project && !isUploadDialogOpen;
  const shouldShowMainContent = !shouldShowError && !shouldShowFullDropzone;

  const activityUrl = window.location.pathname.replace("/data-management", "/activity") + window.location.search;

  const isUploadBtnDisabled = (isFetchingForTheFirstTime || isInProgress);

  const uploadingAlertMsg = uploadManager.isSharedWorker() ? WARNING_SHARED_WORKER : WARNING_REGULAR_UPLOAD;

  // Trigger the removal of upload tasks for already created entities. That will save us a lot of trouble later when
  // e.g. removing entities so that the outdated associated upload tasks are already gone. But don't remove upload
  // tasks during the uploading step so that the overall progress gets displayed correctly.
  useEffect(() => {
    if (uploadTasksToRemove.length && !(state === "uploading" || state === "uploadError")) {
      cleanupUploadTasks(uploadTasksToRemove, uploadManager);
    }
  }, [state, uploadManager, uploadTasksToRemove]);


  // ##### Event handlers ##### //

  /** Toggle between failed processing scans and ok scans */
  function toggleIsShowSuccess(event: React.MouseEvent<HTMLElement>): void {
    
    // Save the selected items of the table that will be hidden
    if (isShowSuccess) {
      setScansToRemoveOkTable(selectedIds);
    } else {
      setScansToRemoveErrorTable(selectedIds);
    }
  
    // Reset the selected items of the table that will be shown
    const idsToReset = isShowSuccess ? selectedIdsOnErrorTable : selectedIdsOnOkTable;
    dispatch(setInitialFetchingItems(idsToReset));
  
    // Toggle the success/error table view
    setIsShowSuccess((isPrev) => !isPrev);
  }

  /** Show the red table with failed scans */
  const isProcessError = state === "processError";

  // ##### JSX element ##### //

  return (
    <div>
      <RemoveScansDialog
        projectId={project.id}
        allItems={tableItems}
        items={scansToRemove}
        state={state}
        isOpen={isRemoveScansDialogOpen}
        setIsOpen={setIsRemoveScansDialogOpen}
      />

      <div>
        <div style={{ float: "left" }}>
          <DataManagementTitle
            isEmpty={isEmpty}
            hasAnyDraftChanges={hasAnyDraftChanges}
            isPublishDisabled={isPublishDisabled}
          />
        </div>
        {
          // If there is a draft change because all scans have been removed, we want to show the Cancel Import button.
          (shouldShowMainContent || (hasAnyDraftChanges && isEmpty)) &&
          <Stack direction="row" sx={{
            float: "right",
            gap: "15px",
          }}>
            <ImportData
              project={project}
              uploadedIdsMap={uploadedIdsMap}
              isUploadDialogOpen={isUploadDialogOpen}
              setIsUploadDialogOpen={setIsUploadDialogOpen}
              isUploadBtnVisible={!isUploadBtnDisabled}
              isUploadBtnDisabled={isUploadBtnDisabled}
              uploadBtnDisabledTooltip={uploadBtnDisabledTooltip}
              workflowState={state}
              isEmpty={isEmpty}
            />
            { (shouldShowMainContent && (publishStepState !== "done" || isDevModeEnabled)) &&
              <PublishButton
                projectId={project.id}
                isDisabled={isPublishDisabled}
                isDevMode={isPublishDevMode}
              />
            }
            { (shouldShowMainContent && publishStepState === "done") &&
              <OpenViewerButton projectId={project.id} />
            }
          </Stack>
        }
      </div>

      {isDevModeEnabled && (
        <ButtonGroup variant="outlined" sx={{ paddingTop: "5px" }}>
          {/* Show warning when viewing read-only in dev mode, since many features won't work. */}
          {!hasRegularPermission &&
            <span
              style={{ color: colorConst.devFeature, margin: "5px" }}
            >
              [READ-ONLY]
            </span>
          }
          <FaroTextButton
            to={activityUrl}
            target="_blank"
            size="small"
            sx={{ color: colorConst.devFeature }}
            onClick={() => {
              // do nothing, we use "to" to open a new tab
            }}
          >
            Activity
          </FaroTextButton>
          <FaroTextButton
            size="small"
            sx={{ color: colorConst.devFeature }}
            onClick={() => void captureTreeInfo()}
          >
            Entities ({ openDraftRevision ? `Draft: ${captureTreeDiff}` : "No Draft" })
          </FaroTextButton>
          <FaroTextButton
            size="small"
            sx={{ color: colorConst.devFeature }}
            onClick={promptMaxConcurrentUploads}
          >
            Concurrent (files, chunks) = ({maxConcurrentUploads || "auto"}, {maxConcurrentChunks || "auto"})
          </FaroTextButton>
          <FaroTextButton
            size="small"
            sx={{ color: colorConst.devFeature }}
            onClick={promptChunkSizeMib}
          >
            Chunks = {chunkSizeMib} MiB
          </FaroTextButton>
          <FaroTextButton
            size="small"
            sx={{ color: colorConst.devFeature }}
            onClick={promptUploadEndpoint}
          >
            Upload endpoint = {uploadEndpoint}
          </FaroTextButton>
        </ButtonGroup>
      )}

      <Box
        id="sa-workflow"
        data-testid="sa-workflow"
        sx={{
          marginTop: "14px",
          border: shouldShowFullDropzone ? undefined : `1px solid ${sphereColors.gray200}`,
          display: "inline-block",
          width: "100%",
        }}
      >

      {shouldShowError && (
        // Since we don't know in which state the project is, we don't show the ImportDataButton here.
        <EmptyPage
          title="Error"
          subtitle="Failed to fetch the uploaded data for this project! Please reload the page and try again."
          icon={FailedIcon}
        />
      )}

      {(isUploading && isUploadingAlertOpen) && (
        <FaroAlert
          severity="warning"
          onClose={() => setIsUploadingAlertOpen(false)}
        >
          <strong style={{marginRight: 15}}>Uploading data...</strong>
          {uploadingAlertMsg}
        </FaroAlert>
      )}

      {(shouldShowFullDropzone || shouldShowHiddenDropzone) && (
        <DataManagementDropzone
          project={project}
          uploadedIdsMap={uploadedIdsMap}
          isStandalone={shouldShowFullDropzone}
        />
      )}

      {shouldShowMainContent && (
        <Box sx={{ background: "white" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: `1px solid ${sphereColors.gray200}`,
              paddingX: "30px",
              paddingTop: "30px",
              paddingBottom: "45px",
              fontWeight: "bold",
            }}
          >
            <div style={{ width: "100%" }}>
              <span style={{ marginRight: "100px", fontSize: "larger", float: "left" }}>
                Blink scans
              </span>

              <DataManagementStepper
                state={state}
                uploadStepState={uploadStepState}
                processStepState={processStepState}
                registerStepState={registerStepState}
                publishStepState={publishStepState}
                uploadProgress={uploadProgress}
                processProgress={processProgress}
                registerProgress={registerProgress}
                setIsUploadDialogOpen={setIsUploadDialogOpen}
                registrationId={registrationId}
                projectId={project.id}
                userId={currentUser?.id ?? ""}
                items={tableItems}
              />
            </div>
          </Box>

          {isProcessError && (
            <div style={{ backgroundColor: sphereColors.red50 }}>
              <Box sx={{ paddingX: "30px", paddingTop: "12px" }}>
                <span style={{ marginRight: "100px", fontSize: "larger", height: "24px", display: "inline-flex", gap: "8px" }}>
                  <SvgIcon inheritViewBox component={ErrorSvg} sx={{ color: sphereColors.red500 }} />
                  Scans failed to process</span>
                <span style={{ marginTop: "-38px", marginRight: "-26px",
                  flexDirection: "column", alignItems: "flex-end", display: "flex" }}>
                <SphereTooltip
                  title={
                    <div>
                      <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {isShowSuccess ? "Show failed scans" : "Show successfully processed scans"}
                      </div>
                      Switch between failed and successfully processed scans.
                    </div>
                  }
                >
                  <FaroIconButton
                    onClick={toggleIsShowSuccess}
                    component={isShowSuccess ? ArrowUpSvg : ArrowDownSvg}
                    iconButtonProps={{ sx: { marginLeft: "2px", top: "1px" } }}
                  />
                </SphereTooltip>
              </span>
                <p>
                  Some scans couldn't be processed and must be removed.
                  You can continue with the processed scans or review the failed ones before deciding.
                </p>
                <Box sx={{ height: "10px" }} />
              </Box>

              {!isShowSuccess && (
                <Box sx={{ paddingX: "30px", paddingBottom: "30px" }}>
                  <DataManagementTable
                    items={tableItems}
                    isFetchingForTheFirstTime={isFetchingForTheFirstTime}
                    state={state}
                    setScansToRemove={setScansToRemove}
                    setIsRemoveScansDialogOpen={setIsRemoveScansDialogOpen}
                    // Filter out the scans with errors in processing
                    filter={(item) => (item.state === "error" && item.type === "Scan")}
                  />
                </Box>)}
            </div>
          )}

          {(!isProcessError || isShowSuccess) && (
            <Box sx={{ paddingX: "30px", paddingBottom: "30px" }}>
              <DataManagementTable
                items={tableItems}
                isFetchingForTheFirstTime={isFetchingForTheFirstTime}
                state={state}
                setScansToRemove={setScansToRemove}
                setIsRemoveScansDialogOpen={setIsRemoveScansDialogOpen}
                filter={isProcessError ? (item) => !(item.state === "error" && item.type === "Scan") : () => true}
              />
            </Box>
          )}
        </Box>
      )}
      </Box>
    </div>
  );
}
