import { Box, Divider, Stack } from "@mui/material";
import { CommonStyles, DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { BulkButtonComponents } from "@components/common/faro-table/faro-table-types";

interface BulkToolbarProps {
  /** Number of rows that is selected in the table */
  numberOfSelectedRows: number;

  /** Total number of rows */
  numberOfRows: number;

  /** Buttons shown in table action bar */
  actionButtons: BulkButtonComponents;
}

/** Toolbar to show for bulk actions */
export function FaroTableToolbar({
  numberOfSelectedRows,
  numberOfRows,
  actionButtons,
}: BulkToolbarProps): JSX.Element {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
      sx={{
        height: "54px",
        pl: "20px",
      }}
    >
      <Box
        component="div"
        sx={{
          width: "220px",
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          textAlign: "left",
        }}
      >
        {!!numberOfSelectedRows && (
          <p>
            <b>{`${numberOfSelectedRows} of ${numberOfRows}`}</b> selected
          </p>
        )}
      </Box>
      <Stack
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: CommonStyles.Borders.gray200Divider,
            }}
          />
        }
        sx={{ overflowX: "auto" }}
      >
        {Object.values(actionButtons).map(
          (actionButton, index) =>
            actionButton !== null && (
              <Box
                component="div"
                key={index}
                sx={{
                  px: "18px",
                }}
              >
                {actionButton}
              </Box>
            )
        )}
      </Stack>
    </Stack>
  );
}
