import { FaroTable } from "@components/common/faro-table/faro-table";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { useDateTime } from "@hooks/use-date-time";
import { useMediaQueryList } from "@hooks/use-media-query";
import { DataManagementBulkActions } from "@pages/project-details/project-data-management/data-management-bulk-actions";
import { getDataManagementTableColumns } from "@pages/project-details/project-data-management/data-management-table-cols";
import { TableHeaders, TableItem, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { useAppSelector } from "@store/store-helper";
import { fetchingMembersFlagsSelector } from "@store/members/members-selector";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";

interface Props {
  /** Array of DataManagementTableItem entities. */
  items: TableItem[];
  /** Whether the uploaded data is being fetched for the first time. */
  isFetchingForTheFirstTime: boolean;
  /** Workflow state. */
  state: WorkflowState;
  /** Callback to set the scans the user selected for removal. */
  setScansToRemove: React.Dispatch<React.SetStateAction<TableItem[]>>
  /** Callback to set whether the confirmation dialog for removing scans is open. */
  setIsRemoveScansDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** Filter on table items */
  filter?: (item: TableItem) => boolean;
}

/** Renders the table in the Staging Area that displays the scan items. */
export function DataManagementTable({
  items,
  isFetchingForTheFirstTime,
  state,
  setScansToRemove,
  setIsRemoveScansDialogOpen,
  filter = undefined,
}: Props): JSX.Element {
  const { companyMembers, projectMembers } = useDataManagementContext();
  const { isScreenXsAndSmall, isScreenMdAndSmaller } = useMediaQueryList();

  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const { isFetchingProjects: isFetchingProjectMembers } = useAppSelector(
    fetchingProjectsFlagsSelector
  );

  const { formatDate } = useDateTime();

  const isLoading = isFetchingForTheFirstTime || isFetchingCompanyMembers || isFetchingProjectMembers;
  const isInProgress = state === "uploading" || state === "processing" || state === "registering" || state === "publishing";

  const bulkActionButtons = DataManagementBulkActions({
    items,
    setScansToRemove,
    setIsRemoveScansDialogOpen,
  });

  let columns: TableHeaders[];
  // Keep in sync with equivalent code part in data-management-table-cols.tsx.
  if (isScreenXsAndSmall) {
    columns = [
      TableHeaders.name,
      TableHeaders.deviceType,
      TableHeaders.status,
      TableHeaders.actions,
    ];
  } else if (isScreenMdAndSmaller) {
    columns = [
      TableHeaders.name,
      TableHeaders.createdBy,
      TableHeaders.deviceType,
      TableHeaders.status,
      TableHeaders.actions,
    ];
  } else {
    columns = [
      TableHeaders.name,
      TableHeaders.createdBy,
      TableHeaders.createdAt,
      TableHeaders.deviceType,
      TableHeaders.status,
      TableHeaders.actions,
    ];
  }

  return (
    <FaroTable
      entities={filter ? items.filter(filter) : items}
      uniqueIdKey={"id"}
      isLoading={isLoading}
      columns={columns.map(
        (column) =>
          getDataManagementTableColumns({
            companyMembers,
            projectMembers,
            formatDate,
            state,
            setScansToRemove,
            setIsRemoveScansDialogOpen,
            isScreenXsAndSmall,
            isScreenMdAndSmaller,
          })[column]
      )}
      rowHeight={54}
      shouldHideCheckbox={isLoading || isInProgress}
      bulkActionButtons={bulkActionButtons}
    />
  );
}
