import { Box, Stack, SxProps, Theme, ThemeProvider, createTheme } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridRowClassNameParams,
  GridRowsProp,
} from "@mui/x-data-grid-pro";
import { withEllipsis } from "@styles/common-styles";
import { BulkButtonComponents } from "@components/common/faro-table/faro-table-types";
import { FilterChipComponents } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  selectedRowIdsSelector,
  updatingItemsSelector,
} from "@store/table/table-selector";
import { setInitialFetchingItems } from "@store/table/table-slice";
import { FaroTableToolbar } from "@components/common/faro-table/faro-table-toolbar";
// This is requested by the MUI team: https://mui.com/x/react-data-grid/getting-started/#typescript
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import { FaroCustomRow } from "@components/common/faro-table/faro-custom-row";
import { FaroCustomCheckbox } from "@components/common/faro-table/faro-custom-checkbox";
import { ReactNode, useMemo, useState } from "react";
import {
  FaroTableFooter,
  FaroTableFooterProps,
} from "@components/common/faro-table/faro-table-footer";
import UpArrow from "@assets/icons/new/arrow-up_10px.svg?react";
import DownArrow from "@assets/icons/new/arrow-down_10px.svg?react";
import { FaroTableFilter } from "@components/common/faro-table/faro-table-filter/faro-table-filter";
import { FaroTableEmptyPage } from "@components/common/faro-table/faro-table-empty-page";

declare module "@mui/x-data-grid-pro" {
  // we need to extend the original interface name to allow custom props in footer in slotProps
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface FooterPropsOverrides extends FaroTableFooterProps {}
}

interface Props<T> {
  /** The entities that the table represent */
  entities: T[];

  /** A key from entity that is unique like ID */
  uniqueIdKey: keyof T;

  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** All the columns and their content to show in table */
  columns: GridColDef[];

  /** Callback fired when a row is clicked. */
  onRowClick?: (id: string | number) => void;

  /** The height of each row. If not provided, the default value will be used */
  rowHeight?: number;

  /** List of all the bulk actions */
  bulkActionButtons?: BulkButtonComponents;

  /** List of all the filter chips performed on the table */
  filterChips?: FilterChipComponents;

  /** Whether to hide the checkbox on each row or not */
  shouldHideCheckbox?: boolean;

  /** Whether to hide the header or not */
  shouldHideHeader?: boolean;

  /** The amount of items to show per page */
  pageSize?: number;

  /** Customize css */
  sx?: SxProps<Theme>;

  /** Used to apply a custom CSS class on each row */
  rowClassName?: (params: GridRowClassNameParams) => string;

  /** List of column fields to hide. These columns exist to use some logics of them such as filtering */
  hiddenColumns?: string[];

  /** Callback fired when the next page button is clicked */
  onNextPage?: () => void;

  /** Callback fired when the previous page button is clicked */
  onPreviousPage?: () => void;

  /** Total number of rows to show in the table (if known) */
  totalCount?: number;

  /** Tooltip to show for the next page button when it is disabled */
  disabledNextPageTooltip?: string;

  /** The component that will render for the side panel  */
  sidePanelContent?: ReactNode;

  /** The size of the side panel  */
  sidePanelContentWidth?: string;

  /** The status of the side panel  */
  isSidePanelOpened?: boolean;
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          marginTop: "1px",
          padding: "0px",
          display: "flex",
          // Show the border on the last visible row
          "& .MuiDataGrid-row--lastVisible": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          },
          // Overwrite custom property that defines the header background color
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "--DataGrid-containerBackground": "rgba(255,255,255,0.0)",
        },
        main: {
          flex: "initial",
        },
        row: {
          "&.Mui-selected": {
            backgroundColor: "rgba(0,0,0,0.05)",
          },
        },
        cell: {
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          color: "rgba(0,0,0,0.8)",
          padding: "8px 10px",
          whiteSpace: "nowrap",
          ...withEllipsis,
          "&:focus-within": {
            outline: "none",
          },
          [`@media (max-width: ${"900px"})`]: {
            verticalAlign: "middle",
          },
        },
        checkboxInput: {
          padding: 0,
        },
        withBorderColor: {
          borderColor: "none",
        },

        toolbarContainer: {
          marginTop: "1px",
          padding: "0px",
        },
        columnHeader: {
          fontSize: "12px",
          color: "rgba(0,0,0,0.6)",
          padding: "0 10px",
          "&:focus-within": {
            outline: "none",
          },
          "&.MuiDataGrid-columnHeader--sorted": {
            color: "rgba(0,0,0,0.8)",
          },
        },
        columnSeparator: {
          display: "none",
        },
        sortIcon: {
          strokeWidth: "2",
          stroke: "rgba(0,0,0,0.8)",
        },
      },
    },
  },
});

const DEFAULT_ROW_HEIGHT_IN_PX = 60;

const DEFAULT_HEADER_HEIGHT_IN_PX = 56;

/** Defines the default amount of items to show per page */
const DEFAULT_PAGE_SIZE = 30;

/**
 * A general component that receives entities and show them in a table.
 * The type of entities is generic <T>, so it can be any type of arrays of object.
 */
export function FaroTable<T>({
  entities,
  uniqueIdKey,
  isLoading,
  columns,
  onRowClick,
  bulkActionButtons,
  filterChips,
  rowHeight,
  shouldHideCheckbox = false,
  shouldHideHeader = false,
  pageSize = DEFAULT_PAGE_SIZE,
  rowClassName,
  sx,
  hiddenColumns,
  onNextPage,
  onPreviousPage,
  totalCount,
  disabledNextPageTooltip,
  sidePanelContent,
  sidePanelContentWidth,
  isSidePanelOpened,
}: Props<T>): JSX.Element {
  const selectedRowIds = useAppSelector(selectedRowIdsSelector);
  const updatingItems = useAppSelector(updatingItemsSelector);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize,
  });
  const dispatch = useAppDispatch();

  /** Shows an empty page when the data is loaded but nothing exist to show */
  const shouldShowEmptyPage = useMemo(
    () => entities.length === 0 && !isLoading,
    [entities.length, isLoading]
  );

  /**
   * Each row should have an id and the entity.
   * The content of the entity is defined in column to have more flexibility (e.g. custom rendering)
   */
  const rows: GridRowsProp = entities.map((entity) => {
    return {
      /** The unique ID of the entity */
      id: entity[uniqueIdKey],

      /**
       * Only entity is added to the row, so that we can have access to the whole entity
       * in columns and set custom rendering when needed
       */
      entity: entity,
    };
  });

  /**
   * Next page button should be disabled manually when the next page is available but not yet loaded
   */
  const shouldDisableNextPage = useMemo(
    () =>
      !!totalCount &&
      rows.length < totalCount &&
      rows.length <= pageSize * (paginationModel.page + 1),
    [pageSize, paginationModel.page, rows.length, totalCount]
  );

  /** Changes the page for pagination */
  function changePage(newPage: number): void {
    if (newPage > paginationModel.page) {
      onNextPage?.();
    }

    if (newPage < paginationModel.page) {
      onPreviousPage?.();
    }

    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: newPage,
    }));
  }

  return (
    <ThemeProvider theme={theme}>
      {filterChips && <FaroTableFilter filterChips={filterChips} />}

      {shouldShowEmptyPage ? (
        <FaroTableEmptyPage />
      ) : (
        <>
          {bulkActionButtons && (
            <FaroTableToolbar
              numberOfSelectedRows={updatingItems.length}
              numberOfRows={entities.length}
              actionButtons={bulkActionButtons}
            />
          )}
          <Stack
            sx={{
              flexDirection: "row",
              gap: sidePanelContent && isSidePanelOpened ? "20px" : "0",
            }}
          >
            <DataGridPro
              rows={rows}
              sx={sx}
              columns={columns.map(
                (column) =>
                  (column = {
                    ...column,
                    // Even though the documentation of MUI makes it appear that if the "sortable" value is not set
                    // it will default to "false", that is not the case for this version of the data grid component.
                    // It used to be the case for previous versions, but for this version it seems that "true" is the default.
                    // That's why we have to explicitly default to "false" if the value is not set for the column.
                    // eslint-disable-next-line @typescript-eslint/naming-convention -- coming from external package
                    sortable: column.sortable ?? false,
                  })
              )}
              slots={{
                /**
                 * We use a custom row to control the visibility of some elements on hover. Every item in the row
                 * where visibility = hidden and class name ${SHOW_ON_HOVER_CLASS}, it will be shown on hover.
                 * This approach is better than using the recommended hoverId state, because it is faster and
                 * reduces the amounts of table rerenders.
                 */
                row: FaroCustomRow,
                baseCheckbox: FaroCustomCheckbox,
                columnSortedAscendingIcon: DownArrow,
                columnSortedDescendingIcon: UpArrow,
                /**
                 * We use a custom footer to show the pagination information.
                 * We don't use the default MUI DataGrid one to have more control over the design, and to go over the
                 * limitations like in projects page where we don't know the total amount of items.
                 */
                footer: FaroTableFooter,
              }}
              slotProps={{
                footer: {
                  paginationModel,
                  totalItems: totalCount ?? entities.length,
                  changePage,
                  shouldDisableNextPage,
                  disabledNextPageTooltip,
                },
              }}
              initialState={{
                columns: {
                  // Hides the columns that should not be visible by adding false to their field
                  columnVisibilityModel: hiddenColumns?.reduce(
                    (acc, key) => ({ ...acc, [key]: false }),
                    {}
                  ),
                },
              }}
              // Hides the filter icon on the right side of the header
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
              checkboxSelection={!shouldHideCheckbox}
              pagination={true}
              pageSizeOptions={[pageSize]}
              paginationModel={paginationModel}
              onPaginationModelChange={(model) => setPaginationModel(model)}
              hideFooter={isLoading}
              loading={isLoading}
              columnHeaderHeight={
                shouldHideHeader ? 0 : DEFAULT_HEADER_HEIGHT_IN_PX
              }
              autoHeight
              rowHeight={rowHeight ?? DEFAULT_ROW_HEIGHT_IN_PX}
              onRowSelectionModelChange={(newSelectedRowIds) => {
                // Type of newSelectedRowIds is readonly, so we need to make a deep copy to prevent type assertion
                dispatch(setInitialFetchingItems([...newSelectedRowIds]));
              }}
              onRowClick={(row) => onRowClick?.(row.id)}
              rowSelectionModel={selectedRowIds}
              getRowClassName={rowClassName}
            />

            {sidePanelContent && (
              <Box
              sx={{
                overflow: "hidden",
                opacity: isSidePanelOpened ? 1 : 0, 
                visibility: isSidePanelOpened ? "visible" : "hidden",
                width: isSidePanelOpened ? `${sidePanelContentWidth}px` : "0",
                transition: "0.3s ease-in-out",
                height: "443px",
                mt: "55px",
              }}
              >
                {sidePanelContent}
              </Box>
            )}
          </Stack>
        </>
      )}
    </ThemeProvider>
  );
}
